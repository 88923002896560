const markets = [
  {
      "name": "Brandon SD Farmers Market",
      "location" :"915 N Splitrock Blvd Brandon SD\n The Lippert/Luverne Truck parking lot\nSaturdays from 9am-2pm\n"+
         "May through October\n",
         // "800 N Splitrock Blvd\nIndoor market at Faith United Church\n" +
         // "Second Saturday of the month 9am-1pm\nNovember through April\n" +
         // "Additional Holiday Market December 17th",
      "linkFb" :"https://www.facebook.com/brandonsdfarmersmarket/",
      "link" :"http://www.brandonsdfarmersmarket.com/",
      "img":"strawberry.svg"
  },
    {
        "name": "Lake Lorraine Farmers Market",
        "location" :"92400 S Marion Rd, Sioux Falls, SD\n East side of the lake,\nThursdays from 4pm - 7pm\n"+
            "June  through October\n\n",
        "linkFb" :"https://www.facebook.com/lakelorrainefarmersmarket",
        "link" :"https://lakelorrainesiouxfalls.com/farmers-market",
        "img":"pineapple.svg"

    },
    {
        "name": "Hartford Farmers Market",
        "location" :"Main Ave, Hartford, SD\nFrom Modish Boutique to Menth St (South Bar.)\n"+
            "First Monday of the month from 5pm - 8:30pm\nJune through September",
        "link" :"https://www.hartfordsdfarmersmarket.com/",
        "linkFb" : "https://www.facebook.com/HartfordSDFarmersMarket/",
        "img":"pear.png"

    },
    {
        "name": "KELOLAND Living Arts & Crafts Show",
        "location" :"Ramkota Exhibit Hall\n3200 W Maple St, Sioux Falls, SD 57107\n"+
            "October 20th – 12pm-8pm\n" +
            "October 21st, 2023 – 9am-5pm!\n",
        "link" :"https://www.keloland.com/keloland-living/arts-crafts-show/",
        "linkFb" : "https://www.facebook.com/events/182486902836245/220122239072711/",
        "img":"citrus.png"

    },
    // {
    //     "name": "605 Made Night Market",
    //     "location" :"Southeast corner of 6th Street and Phillips Avenue in downtown Sioux Falls\n"+
    //         "August 26, 2023 from 3pm - 9pm",
    //     "linkFb" :"https://fb.me/e/1fADd2LWn",
    //     "img":"pear.png"
    //
    // },
    {
        "name": "Brookings Summer Arts Festival",
        "location" :"104 6th St W, Brookings SD \nSaturday, July 8 from 10am - 7pm\n"+
            "Sunday, July 9, 2023 from 10am - 5pm\n\n",
        "linkFb" :"https://www.facebook.com/BrookingsSummerArtsFestival/?ref=br_rs",
        "link" :"https://bsaf.com/",
        "img":"banana2.png"

    },

];
export default markets;
const price = "$3.00"
const produts = [
  {
    "name": "Apple",
     "price" :price,
     "img": "Fruit/Apple.JPG"
  },
  {
    "name": "Banana",
     "price" :price,
    "img": "Fruit.jpg"
  },
  // {
  //   "name": "Grapefruit",
  //    "price" :price,
  //   "img": "Fruit.jpg"
  // },
  {
    "name": "Kiwi",
     "price" :price,
    "img": "Fruit.jpg"
  },
  //   {
  //   "name": "Lime",
  //    "price" :"UPON REQUEST",
  //   "img": "Fruit.jpg"
  // },
    {
        "name": "Mango",
        "price" :price,
        "img": "Fruit.jpg"
    },
    {
        "name": "Pineapple",
        "price" :price,
        "img": 'Fruit.jpg',
    },
    {
        "name": "Papaya",
        "price" :price,
        "img": "Fruit.jpg"
    },
    // {
    //     "name": "Pear",
    //     "price" :price,
    //     "img": "Fruit.jpg"
    // },
    {
        "name": "Strawberry",
        "price" :price,
        "img": "Fruit.jpg"
    },
    {
    "name": "Watermelon",
     "price" :price,
    "img": "Fruit.jpg"
  },
  {
    "name": "Snack Mix",
      "description": "Available in Original, Spicy, Honey Roasted \nand Bacon Ranch ",
      "price" : "$6.00",
    "img": "Fruit.jpg"
  },
    {
        "name": "Trail Mix",
        "price" :"$5.00",
        "img": "Fruit.jpg"
    },

];
export default produts;